import { useState } from "react";

import AppContext from "./AppContext";

const initialState = {
    windows: [],
    taskElems: [],
};

const AppProvider = ({ children }) => {
    const [appState, setAppState] = useState(initialState);

    return (
        <AppContext.Provider value={{ appState, setAppState }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
