import { useContext } from "react";
import AppContext from "../context/AppContext";

function TopBar({ name, type }) {
    const { appState, setAppState } = useContext(AppContext);
    const exitHandler = (label) => {
        var window;
        appState.windows.forEach((w) => {
            if (w.label === label) window = w;
        });
        setAppState({
            ...appState,
            windows: appState.windows.filter((w) => {
                return window !== w;
            }),
        });
    };

    return (
        <div className="title-bar">
            <div className="title-bar-text">
                {name}
                {type}
            </div>
            <div className="title-bar-controls">
                <button aria-label="Minimize"></button>
                <button aria-label="Maximize"></button>
                <button
                    aria-label="Close"
                    onClick={() => exitHandler(name)}
                ></button>
            </div>
        </div>
    );
}

export default TopBar;
