const tswerti =
    '<div className = "normal" id="second">\
..................................................<br/>\
..................................................<br/>\
.....................,%&&@@@@&&...................<br/>\
.................%@@@@@@@@&&@@@@@%................<br/>\
..............,&%@@@@@@@@@@@@@@@@@&...............<br/>\
..............,(@@@@@@@@%((((%&@@@@o..............<br/>\
..............,@@@@%#((///((((##%@@&..............<br/>\
.............,,@@@#&@@@&#(#%@@@&#@@&..............<br/>\
..............,%@#(%%#%##(#%#%&%##@&..............<br/>\
.............,,/(#((//(##(##(((((##o..............<br/>\
..............,o((((((##&&&&#(((##(,..............<br/>\
...............,oo(/(##%#%%%%###(,,...............<br/>\
................,o(####(##(#####,.................<br/>\
.................,###&&&%##&&&##,.................<br/>\
................o@######%##%####%/................<br/>\
...........,@@@@@@%###(#(((#(###%@@@@&............<br/>\
......&@@@@@@@@@@@@@@@@(((((#@@@@@@@@@@@@@@#......<br/>\
..&@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@..<br/>\
.@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@.<br/>\
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@<br/>\
</div>';

const markdown = `
### Anas Elouargui
<div id="wrapper">

<div id="first">
** Email address ** : anas.elouargui@gmail.com<br/>

** Phone number ** : +212 7 08 30 03 97<br/>

** LinkedIn ** : www.linkedin.com/in/aelouarg/<br/>

** Github ** : www.github.com/drslax<br/>
</div>
${tswerti} 
</div>
---

### Education

**1337 Coding school - Khouribga  |  ** \` 2018-2022 \`<em> (expected)</em>
* Algorithms
* Problem solving \* Artificial intelligence (ML - DL..)

**Preparatory Classes (CPGE) at high school Salman Elfarissi - Sale  |  ** \` 2015-2017 \`
* TSI : Industrial Technology and Science

### Experience

**WafR, Casablanca - MA — Data Scientist** (Full-time) SEP. 2021 - PRESENT
-   WafR is a startup who created a mobile app for gaining cashbacks upon buying products from grocers.
    -   As a Data Scientist, my tasks are:
    -   Enhance the cashback algorithm.
    -   Clean, analyze, and interpret the data.

**WafR, Casablanca - MA — Data Scientist** (Part-time) MAR. 2021 - AUG. 2021
-   My task was to create an algorithm for cashback.

**OCP S.A, Ben Guerir - MA — Software Developer** (Internship) SEP. 2019 - FEB. 2020
-   The internship was about programming a self-driving truck using the LIDAR-Camera and RADAR technologies.
-   My tasks were both to collect and process the data provided by the RADAR & LIDAR.

**PickOne, Junior web developer**

-   PickOne is a social media app, that I developed along with the IT team using Flutter/ExpressJS.

---

### My Cool Side Project
#### soon...

---

-   Human Languages:

    -   English (Operational)
    -   French (Operational)
    -   Arabic (Native)

`;
export default markdown;
