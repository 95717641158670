/* import icons */

import folder_close from "../icons/directory_closed.png";
import trash_empty from "../icons/recycle_bin_empty.png";
import trash_full from "../icons/recycle_bin_full.png";
import folder_open from "../icons/directory_open.png";
import media_player from "../icons/media_player.png";
import private_file from "../icons/file_padlock.png";
import document_ico from "../icons/document.png";
import computer_ico from "../icons/computer.png";
import user_world from "../icons/user_world.png";
import msagent_ico from "../icons/msagent.png";
import outlook_ico from "../icons/outlook.png";
import social_ico from "../icons/social.png";
import video_file from "../icons/video.png";
import midi_file from "../icons/midi.png";
import html_ico from "../icons/html.png";
import chart from "../icons/chart.png";

/* import markdowns*/

import markdown from "../markdowns/about_me.js";

/* import components */

import Explorer from "./explorer";
import Notepad from "./notepad";
import AccessDenied from "./accessDenied";

const socialItems = [
    { label: "Github", icon: html_ico, link: "https://github.com/drslax" },
    {
        label: "LinkedIn",
        icon: outlook_ico,
        link: "https://www.linkedin.com/in/aelouarg/",
    },
    {
        label: "Twitter",
        icon: user_world,
        link: "https://twitter.com/ElouarguiAnas",
    },
];

const fromScratchItems = [
    { label: "Logistic Regression", icon: chart },
    { label: "Multilayer Perceptron", icon: chart },
    { label: "Linear Regression", icon: chart },
    { label: "K-means", icon: chart },
];

const desktopItems = [
    {
        label: "About Me",
        icon: msagent_ico,
        window: Notepad({ name: "About Me", markdown: markdown }),
        clicked: false,
    },
    {
        label: "Social_Media",
        icon: social_ico,
        window: Explorer({ items: socialItems, name: "Social_Media" }),
        clicked: false,
    },
    {
        label: "From_Scratch",
        icon: folder_close,
        window: Explorer({ items: fromScratchItems, name: "From_Scratch" }),
        clicked: false,
    },
    {
        label: "WafR",
        icon: private_file,
        window: AccessDenied({
            name: "WafR",
            text: "WafR is not accessible",
            clicked: false,
        }),
    },
];

export { desktopItems, socialItems };
