import { useContext } from "react";
import AppContext from "../context/AppContext";

const onclick_1 = (event) => {
    let parent = event.target.parentElement;
    let selected = "text-selected";

    if (parent.classList.contains(selected)) parent.classList.remove(selected);
    else parent.classList.add(selected);
};

function DesktopIcons({ item, type }) {
    const { appState, setAppState } = useContext(AppContext);

    const clickHandler = (wind) => {
        if ((type !== "explorer") & !appState.windows.includes(window)) {
            setAppState({
                ...appState,
                windows: [...appState.windows, wind],
            });
        } else if (type === "explorer") {
            window.open(item.link);
        }
    };

    return (
        <div
            className="desktop-icon"
            onClick={onclick_1}
            onDoubleClick={() => clickHandler(item)}
        >
            <img className="desk-icon" src={item.icon} alt={item.label} />
            <p className="text-not-selected">{item.label}</p>
        </div>
    );
}

function IconsList({ items, type }) {
    return (
        <div className={type}>
            {items.map((item, index) => (
                <DesktopIcons key={index} item={item} type={type} />
            ))}
        </div>
    );
}

export default IconsList;
