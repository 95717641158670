import { useContext } from "react";

import AppContext from "../context/AppContext";
import Markdown from "markdown-to-jsx";
import TopBar from "./topBar";

const Notepad = ({ name, markdown }) => {
    return function Window() {
        let indx = 0;
        const { appState } = useContext(AppContext);
        const len = appState.windows.length;

        for (var i = 0; i < len; i++) {
            if (appState.windows[i].label === name) {
                indx = i;
            }
        }

        return (
            <div
                className="window window-css"
                style={{
                    zIndex: indx,
                    marginTop: 3 * indx + "vh",
                }}
            >
                <TopBar name={name} type={"- Notepad"} />
                <div className="notepad-bar bar-p">
                    <p>File</p>
                    <p>Edit</p>
                    <p>Search</p>
                    <p>Help</p>
                </div>
                <div className="window-body">
                    <div className="window-inner notepad-scroll">
                        <div style={{ height: "0px" }}>
                            <Markdown
                                children={markdown}
                                options={{
                                    disableParsingRawHTML: false,
                                    forceBlock: true,
                                }}
                            ></Markdown>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default Notepad;
