import { useContext } from "react";
import AppContext from "../context/AppContext";

import error from "../icons/msg_error.png";

const AccessDenied = ({ name, text }) => {
    return function AccessDnd() {
        const { appState, setAppState } = useContext(AppContext);
        const exitHandler = (label) => {
            var window;
            appState.windows.forEach((w) => {
                if (w.label === label) window = w;
            });
            setAppState({
                ...appState,
                windows: appState.windows.filter((w) => {
                    return window !== w;
                }),
            });
        };
        return (
            <div className="window access-denied">
                <div className="title-bar">
                    <div className="title-bar-text">{name}</div>
                    <div className="title-bar-controls">
                        <button disabled>X</button>
                    </div>
                </div>
                <div className="window-body">
                    <div className="text-icon">
                        <img src={error}></img>
                        <p>{text}</p>
                    </div>
                    <button onClick={() => exitHandler(name)}>OK</button>
                </div>
            </div>
        );
    };
};

export default AccessDenied;
