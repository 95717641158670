import { useContext } from "react";
import IconsList from "./components/iconsList";
import TaskBar from "./components/taskBar";
import AppContext from "./context/AppContext";

import "98.css";
import "./App.css";

const { desktopItems } = require("./components/items.js");

function App() {
    const { appState } = useContext(AppContext);
    return (
        <div className="desktop">
            <IconsList items={desktopItems} type="desk_icons" />
            {appState?.windows?.map((Window, index) => (
                <Window.window key={index} />
            ))}
            <TaskBar />
        </div>
    );
}

export default App;
