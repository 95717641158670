import { useContext } from "react";
import AppContext from "../context/AppContext";
import start_button from "../icons/start-button.png";

const TaskBarElems = ({ item }) => {
    return (
        <button
            className={item.clicked ? "taskElem button-active" : "taskElem"}
        >
            <img src={item.icon} className="TaskElemIcon"></img>
            <p>{item.label}</p>
        </button>
    );
};

const TaskBar = () => {
    const { appState } = useContext(AppContext);

    function activeLastElem({ elemIndex = "last" }) {
        let elemClicked = elemIndex;
        if (elemIndex === "last") elemClicked = appState.windows.length - 1;
        for (var i = 0; i < appState.windows.length; i++) {
            if (i !== elemClicked) {
                appState.windows[i].clicked = false;
            } else {
                appState.windows[i].clicked = true;
            }
        }
    }

    activeLastElem("last");

    return (
        <div className="window footer">
            <button>
                <img src={start_button}></img>
            </button>
            <div style={{ width: "10px" }}></div>
            {appState.windows.map((item, index) => (
                <TaskBarElems item={item} />
            ))}
        </div>
    );
};

export default TaskBar;
