import { useContext } from "react";

import AppContext from "../context/AppContext";
import IconsList from "./iconsList";
import TopBar from "./topBar";

function QuickAccess({ items }) {
    return (
        <select style={{ width: "50vw" }}>
            {items.map((item, index) => (
                <option key={index}>{item.label}</option>
            ))}
        </select>
    );
}

function Explorer({ items, name }) {
    return function Window() {
        let indx = 0;
        const { appState } = useContext(AppContext);
        const len = appState.windows.length;

        for (var i = 0; i < len; i++) {
            if (appState.windows[i].label === name) {
                indx = i;
            }
        }

        return (
            <div
                className="window window-css"
                style={{
                    zIndex: indx,
                    marginTop: 3 * indx + "vh",
                }}
            >
                <TopBar name={name} />
                <div className="navBar bar-p">
                    <p>Address</p>
                    <QuickAccess items={items} />
                    <button>File</button>
                </div>
                <div className="window-body">
                    <div className="window-inner">
                        <IconsList items={items} type="explorer" />
                    </div>
                </div>
            </div>
        );
    };
}

export default Explorer;
